var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"spinning":_vm.spinShow}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"主机(host)","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'host',
          { rules: [{ required: true, message: '主机不能为空' }], initialValue: '202.100.100.50:4443' },
        ]),expression:"[\n          'host',\n          { rules: [{ required: true, message: '主机不能为空' }], initialValue: '202.100.100.50:4443' },\n        ]"}],attrs:{"placeholder":"请输入主机地址"}})],1),_c('a-form-item',{attrs:{"label":"秘钥(appKey)","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['appKey', { rules: [{ required: true, message: '秘钥不能为空' }], initialValue: '27339437' }]),expression:"['appKey', { rules: [{ required: true, message: '秘钥不能为空' }], initialValue: '27339437' }]"}],attrs:{"placeholder":"请输入秘钥"}})],1),_c('a-form-item',{attrs:{"label":"秘钥(appSecret)","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'appSecret',
          { rules: [{ required: true, message: '秘钥不能为空' }], initialValue: 'zATVBCzPOAAiHtnWBzAz' },
        ]),expression:"[\n          'appSecret',\n          { rules: [{ required: true, message: '秘钥不能为空' }], initialValue: 'zATVBCzPOAAiHtnWBzAz' },\n        ]"}],attrs:{"placeholder":"请输入秘钥"}})],1),_c('a-form-item',{attrs:{"label":"所属部门","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-tree-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['departmentId', { rules: [{ required: true, message: '部门不能为空' }], initialValue: _vm.orgId }]),expression:"['departmentId', { rules: [{ required: true, message: '部门不能为空' }], initialValue: orgId }]"}],attrs:{"dropdownStyle":{ maxHeight: '400px', overflow: 'auto' },"treeData":_vm.orgTree,"placeholder":"请选择部门"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }